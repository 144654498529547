import React, { Component } from 'react';
import styles from './BuildingInstructions.module.css';
import { isLoggedIn, isStudent, isFranchiseOwner, isAdministrator } from "../components/functions";

import Loader from "../loader.gif";

import NavbarAdmin from "../components/NavbarAdmin";
import Navbar from "../components/Navbar";
import NavbarStudents from "../components/NavbarStudents";
import NavbarLogOut from "../components/NavbarLogOut";

import { useMediaQuery } from 'react-responsive';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx'
import Radium from 'radium';

import { withRouter } from 'react-router-dom';

import BackgroungUrl from '../images/WhiteBackground.jpg';
import MobileBackgroungUrl from '../images/WhiteBackgroundMobile.jpg';




const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 767 })
    return isDesktop ? children : null
}


@inject('pages')
@observer // REMOVE BRACKETS => @observer
class TermsOfUse extends Component {


    componentDidMount() {
        // this.props.pages.LoadPageData('51');

    }

    render() {
       
        let Header = <NavbarLogOut />;

        isStudent() ? Header = <NavbarStudents /> : null

        isFranchiseOwner() ? Header = <Navbar /> : null

        isAdministrator() ? Header = <NavbarAdmin /> : null

      

       

        return (


            <React.Fragment>
                {Header}

                {this.props.pages.loading && <img className="loader" src={Loader} alt="Loader" />}
                <div className='PageConatiner'>
                    <div className={styles.Title}>Terms Of Use</div>

                    <div onClick={() => this.props.history.goBack()} className={styles.GoBackTermOfUse}>{"<< "}Go Back</div>

                    <Desktop> <div className={styles.BackgroungImg}><img src={BackgroungUrl} alt="dvb"></img></div> </Desktop>
                    <Mobile> <div className={styles.MobileBackgroungImg}><img src={MobileBackgroungUrl} alt="dvb"></img></div> </Mobile>

                    <div className="FlexContainer">

                        {this.props.pages.TermsOfUse}
                    </div>

                </div>
            </React.Fragment>
        );


    }
}

export default withRouter(Radium(TermsOfUse));
