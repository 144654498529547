import React from 'react';
// import { Router } from "@reach/router";

import { Switch, Route } from "react-router-dom";

import LoginScreen from './screens/LoginScreen';
import StudentsList from './screens/StudentsList';
import SignUpNewStudent from './screens/SignUpNewStudent';
import LessonPlans from './screens/LessonPlans';
import BuildingInstructions from './screens/BuildingInstructions';
import ModelsPage from './screens/SingleProgram/ModelsPage';
import LessonsPage from './screens/SingleProgram/LessonsPage';
import SingleModelPage from './screens/SingleProgram/SingleModelPage';
import AdminAllTeachers from './screens/AdminAllTeachers';
import AdminAllStudents from './screens/AdminAllStudents';
import SignUpNewTeacher from './screens/SignUpNewTeacher'
import TermsOfUse from './screens/TermsOfUse'


import PasswordReset from './screens/PasswordReset';
import { StyleRoot } from 'radium';

import './App.css';


class App extends React.Component {
  render() {
    return (

      <StyleRoot>
        <div className="AppContainer">

          <Switch>

            <Route path="/PasswordReset">
              <PasswordReset />
            </Route>


            <Route path="/students_list/:userName/">
              <StudentsList />
            </Route>


            <Route path="/sign_up_new_student/:userName">
              <SignUpNewStudent />
            </Route>


            <Route exact path="/lesson_plans/:userName" >
              <LessonPlans />
            </Route>

            <Route path="/lesson_plans/:userName/:programName" >
              <LessonsPage />
            </Route>


            <Route exact path="/building_instructions/:userName" >
              <BuildingInstructions />
            </Route>


            <Route path="/building_instructions/:userName/:programName" >
              <ModelsPage />
            </Route>

            <Route path="/model_building_instructions/:userName/:programName/:ModelName">
              <SingleModelPage />
            </Route>


            <Route exact path="/">
              <LoginScreen />
            </Route>

          </Switch>


          <Route path="/admin/Franchise_Owners_List" >
            <AdminAllTeachers />
          </Route>

          <Route path="/admin/all_students_list" >
            <AdminAllStudents />
          </Route>

          <Route path="/admin/sign_up_new_franchise" >
            <SignUpNewTeacher />
          </Route>


          <Route path="/terms_of_use" >
            <TermsOfUse />
          </Route>

        </div>
      </StyleRoot>

    );
  }
}

export default App;







