import React from 'react';
import styles from './SelectProgram.module.css';
import SelectProgramItem from '../components/SelectProgramItem';
import { getUserName } from "../components/functions";

import { Link } from 'react-router-dom'

import LogoPlayMachines1 from '../images/LogoPlayMachines1.png';
import LogoPlayMachines2 from '../images/LogoPlayMachines2.png';

import TopTitleBackground from '../images/top_blueDuble.png'

class SelectLessonPlansGrid extends React.Component {

    render() {

        const userName = (getUserName()) ? getUserName() : '';
        const programName = 'Play Machines 1'





        return (
            <div className={styles.FlexContainer}>

                <div className={styles.ItemsContainer}>


                    {/* ITEM TO LOOP FROM DATA BASE */}
                    <div className={styles.Item}>
                        <Link to={`/lesson_plans/${userName}/${programName}/`}>
                            <SelectProgramItem
                                TopLegoImage={TopTitleBackground}
                                Title="Play Machines"
                                RomanNumber=" I"
                                TopPosition='-7px'
                                ProgramLogo={LogoPlayMachines1}
                            />
                        </Link>
                    </div>
                    {/* ITEM TO LOOP FROM DATA BASE */}


                    <div className={styles.Item}>
                        <Link to={`/lesson_plans/${userName}/Play Machines 2/`}>
                            <SelectProgramItem
                                TopLegoImage={TopTitleBackground}
                                Title="Play Machines"
                                RomanNumber=" II"
                                TopPosition='-7px'
                                ProgramLogo={LogoPlayMachines2}

                            />
                        </Link>
                    </div>


                </div>


            </div>

        );
    }
}

export default SelectLessonPlansGrid;