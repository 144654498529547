import React, { Component } from 'react';
import styles from './SignUpNewStudent.module.css';

import "react-datepicker/dist/react-datepicker.css";

import { isLoggedIn, isFranchiseOwner } from "../components/functions";
import Navbar from "../components/Navbar";
import FromSingUpNewStudent from '../components/FromSingUpNewStudent';


import BackgroungUrl from '../images/WhiteBackground.jpg';
import MobileBackgroungUrl from '../images/WhiteBackground.jpg';

class SignUpNewStudent extends Component {


	render() {


		const BackgroundStyle = {
			backgroundImage: 'url(' + BackgroungUrl + ')',

			//On moble screen
			'@media (max-width: 801px)': {
				backgroundImage: 'url(' + MobileBackgroungUrl + ')',
			}

		};


		return (

			!isLoggedIn() || !isFranchiseOwner() ? (

				window.location.href = '/'

			) :
				<React.Fragment>
					<Navbar />
					<div style={BackgroundStyle} className={styles.PageConatiner}>

						<FromSingUpNewStudent />

					</div>
				</React.Fragment>
		);


	}
}

export default SignUpNewStudent;
