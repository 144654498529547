import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../client-config';
import ThSort from './ThSort';
import Loader from "../loader.gif";
import styles from '../screens/StudentsList.module.css';

import { Table, Tbody, Thead, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { getUserName } from "../components/functions";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { RiFileExcel2Line } from 'react-icons/ri';

class TableTeacherStudents extends Component {


	constructor(props) {
		super(props);

		this.state = {
			USERS: [],
			STUDENTS: [],
			teacher_id: localStorage.getItem('userId'),
			loading: false,
			HaveUsers: false,
			th1_Active: false,

			th1_Direction: false,
			th2_Direction: false,
			th3_Direction: false,
			th4_Direction: false,
			th5_Direction: false,
			th6_Direction: false,
			th7_Direction: false,
			th8_Direction: false,
			th9_Direction: false,

			th1_Active: false,
			th2_Active: false,

		}
	}

	componentDidMount() {

		const wordPressSiteUrl = clientConfig.siteUrl;
		const AUTH_KEY = clientConfig.AUTH_KEY;

		let data_to_post = {}
		data_to_post.AUTH_KEY = AUTH_KEY
		// data_to_post.teacher_id = UserId

		this.setState({ loading: true }, () => {

			axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/get_all_users_data`, data_to_post, {
				headers: {
					'Content-Type': 'application/json',
				}
			})
				.then(res => {
					this.setState({ USERS: res.data, loading: false })

					const FranchiseOwner = this.state.USERS.filter(user => user.role === 'franchise_owner')
					const AllStudents = this.state.USERS.filter(user => user.role === 'student')


					if (FranchiseOwner.length >= 1) {

						// add NUMBER OF STUDENTS
						for (let i = 0; i < FranchiseOwner.length; i++) {
							let obj = [];
							obj = AllStudents.filter(student => student.teacher_id === JSON.stringify(FranchiseOwner[i].user_id))

							Object.assign(FranchiseOwner[i], { numberOfStudents: JSON.stringify(obj.length) });
							// console.log(FranchiseOwner)
						}

						this.setState({ HaveUsers: true, USERS: FranchiseOwner })

					}

				})
				.catch(err => {
					this.setState({ loading: false })
				})

		})



	};




	GotoStudentslist = (UserID, UserName) => {
		localStorage.setItem('userId', UserID);

		window.location.href = '/students_list/' + UserName;
	}


	ToggleIcon = (th) => {
		const direction = th + '_Direction';
		const active = th + '_Active';

		// MAKE SELECTED ACTIVE 
		if (!this.state[active]) {

			this.setState({ th1_Active: false });
			this.setState({ th2_Active: false });


			this.setState({ [active]: true });

		}

		const thisth = this.state[direction]
		this.setState({ [direction]: !thisth });

		this.SwitchSort(th)
	}






	SwitchSort = (th) => {
		const direction = th + '_Direction';
		const active = th + '_Active';
		const thisth = this.state[direction]

		const Isactive = this.state[active]
		const users = this.state.USERS


		if (th == 'th1') {

			if (thisth) {
				// SORT A TO Z
				users.sort((a, b) => a.FirstName.localeCompare(b.FirstName))
				this.setState({ USERS: users })
			}

			if (!thisth) {
				// SORT Z TO A
				users.sort((a, b) => b.FirstName.localeCompare(a.FirstName))
				this.setState({ USERS: users })
			}
		}




		if (th == 'th2') {

			if (thisth) {
				// SORT A TO Z
				users.sort((a, b) => a.LastName.localeCompare(b.LastName))
				this.setState({ USERS: users })
			}

			if (!thisth) {
				// SORT Z TO A
				users.sort((a, b) => b.LastName.localeCompare(a.LastName))
				this.setState({ USERS: users })
			}
		}







	}





	render() {



		const { loading,

			th1_Active,
			th2_Active,
			th3_Active,
			th4_Active,
			th5_Active,
			th6_Active,
			th7_Active,
			th8_Active,
			th9_Active,

			th1_Direction,
			th2_Direction,
			th3_Direction,
			th4_Direction,
			th5_Direction,
			th6_Direction,
			th7_Direction,
			th8_Direction,
			th9_Direction,



		} = this.state;




		// Email: "Aff@aas.crr"
		// FirstName: ""
		// LastName: ""
		// RegisteredDate: "2020-04-26 17:14:01"
		// lessons_end_date: "2020-04-21T21:00:00.000Z"
		// lessons_start_date: "2020-04-20T21:00:00.000Z"
		// number_of_lessons: "22"
		// parent_email: "Aff@aas.crr"
		// parent_name: "Ff"
		// phone: "+972525521108"
		// program: "Play Machines 1"
		// role: "student"
		// student_first_name: "גיא"
		// student_last_name: "סלח"
		// teacher_id: "65"
		// user_id: 77


		// ThisTeacherStudents.sort((a, b) => a.user_id - b.user_id)

		// ThisTeacherStudents.sort((a, b) => a.lessons_start_date - b.lessons_start_date)


		const userName = (getUserName()) ? getUserName() : '';


		const tablelist = this.state.USERS.map((user) =>
			<Tr key={user.user_id} >
				<Td>{user.FirstName}</Td>
				<Td>{user.LastName}</Td>
				<Td>{user.Email}</Td>
				<Td>{user.Country}</Td>
				<Td>{user.State}</Td>
				<Td>{user.Territory}</Td>
				<Td className={styles.NumOfStudents}>{user.numberOfStudents}</Td>
				<Td className={styles.ButtonField}><button className={styles.StudentsListBt} onClick={this.GotoStudentslist.bind(this, user.user_id, user.FirstName)} >Students List</button></Td>


			</Tr>
		);

		const FullTable = <>
			<div className={styles.Title}>Franchise Owners List</div>

			<Table>

				<Thead>
					<Tr>


						<Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th1')} Direction={th1_Direction} Active={th1_Active} Title="First Name" /></Th>
						<Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th2')} Direction={th2_Direction} Active={th2_Active} Title="Last Name" /></Th>
						<Th>email</Th>
						<Th>Country</Th>
						<Th>State</Th>
						<Th>Territory</Th>
						<Th className={styles.NumOfStudents}>Students</Th>
						<Th></Th>

					</Tr>
				</Thead>

				<Tbody>
					{tablelist}
				</Tbody>
			</Table>

			<div className="download-table-xls-container">
				<ReactHTMLTableToExcel
					id="test-table-xls-button"
					className="download-table-xls-button"
					table="TeachersStudents"
					filename={'Franchise Owners YE Online'}
					sheet={'Franchise Owners'}
					buttonText={<RiFileExcel2Line />} />
			</div>
		</>

		// THIS IS DUCPLICATE as above table for download with out multiheaders  (<Thead> was removed)
		const FullTableToDownload = <>

			<Table id="TeachersStudents">
				<Tr>
					<Th>First Name</Th>
					<Th>Last Name</Th>
					<Th>email</Th>
					<Th>Country</Th>
					<Th>State</Th>
					<Th>Territory</Th>
					<Th>Number of Students</Th>
					<Th>Students List</Th>
				</Tr>

				<Tbody>
					{tablelist}
				</Tbody>
			</Table>
		</>
		// THIS IS DUCPLICAT as above table for download with out multiheaders 

		return (

			<>
				<div className="hidden">{FullTableToDownload}</div>


				{this.state.HaveUsers ?
					FullTable
					:
					null
				}

				{loading && <img className="loader" src={Loader} alt="Loader" />}

				{/* <div className={styles.FlexContainer}>
					<div className={styles.SingUpNewLink}>
						<Link to={`/sign_up_new_student/${userName}`}>
							<div className={styles.StudentListAddUser}>
								<img src={userIcon} alt="userIcon"></img>
								<div className={styles.AddStudentText}>Sign Up New Student</div>
							</div></Link>

						{loading && <img className="loader" src={Loader} alt="Loader" />}
					</div>
				</div> */}
			</>

		);
	}

}



export default TableTeacherStudents;

