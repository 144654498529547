import React from 'react';
import styles from './Navbar.module.css';
import { getUserName } from "./functions";
import { slide as Menu } from 'react-burger-menu'
import NavLink from './NavLink';
import { withRouter } from "react-router-dom";

import './Navbar.css'
import Radium from 'radium';
import BackgroungUrl from '../images/Header_kids.jpg';
import MobileBackgroungUrl from '../images/Header_kidsMobile.jpg';

class Navbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }


    handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('userRole');
        window.location.href = '/';
    };


    render() {
        const userName = (getUserName()) ? getUserName() : '';
        console.log(this.props)
        const BackgroundStyle = {
            backgroundImage: 'url(' + BackgroungUrl + ')',

            //On moble screen
            '@media (max-width: 801px)': {
                backgroundImage: 'url(' + MobileBackgroungUrl + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            }

        };

        return (
            <>
                <Menu right>
                    <NavLink to={`/building_instructions/:userName/Play Machines 1`}>Play Machines <span className={styles.RomanNumber}>I</span></NavLink>
                    <NavLink to={`/building_instructions/:userName/Play Machines 2`}>Play Machines <span className={styles.RomanNumber}>II</span></NavLink>
                    <NavLink to={`/terms_of_use`}>Terms Of Use</NavLink>
                    <div className='LogOutMobile NavLinkMobile' onClick={this.handleLogout} >Log out</div>
                </Menu>
                <div className={styles.NavigationContainer}>


                    <div style={BackgroundStyle} className={styles.NavBar} >
                        <div className={styles.LeftDiv}>
                            <div className={styles.StudentName}>
                                Hi {userName}
                            </div>
                        </div>
                        <div className={styles.CenterDivStudent}>
                            <div className={styles.TitleAndLink}>
                                <div className={styles.StudentTitle}>{this.props.Title}</div>
                                <div onClick={() => this.props.history.goBack()} className={styles.StudentGoBack}>{this.props.GoBack}</div>
                            </div>
                        </div>
                        <div className={styles.RightDiv}>

                            <div>
                                <button onClick={this.handleLogout} className={styles.LogOutBt + ' logoutBtn'}>Log Out</button>
                                <NavLink className="TermsNavLink" to={`/terms_of_use`}>Terms Of Use</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Radium(Navbar));


