import React, { Component } from 'react';

import styles from './StudentsList.module.css';

import { isLoggedIn, isAdministrator } from "../components/functions";
import NavbarAdmin from "../components/NavbarAdmin";

import TableTeachersAdmin from '../components/TableTeachersAdmin'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'


import BackgroungUrl from '../images/WhiteBackground.jpg';
import MobileBackgroungUrl from '../images/WhiteBackgroundMobile.jpg';




class StudentsList extends Component {

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.location.pathname !== this.props.location.pathname) {
			window.location.reload();
		}
	};


	render() {

		const BackgroundStyle = {
			backgroundImage: 'url(' + BackgroungUrl + ')',

			//On moble screen
			'@media (max-width: 801px)': {
				backgroundImage: 'url(' + MobileBackgroungUrl + ')',
			}

		};



		return (

			!isLoggedIn() || !isAdministrator() ? (

				window.location.href = '/'

			) :

				<React.Fragment>

					<NavbarAdmin />
					<div style={BackgroundStyle} className={styles.PageConatiner}>


						<div className={styles.Container}>
						
							<TableTeachersAdmin />


						</div>

					</div>

				</React.Fragment>
		);
	}

}



export default StudentsList;

