import React from 'react';
import NavLink from './NavLink';
import styles from './Navbar.module.css';
import { getUserName } from "./functions";
import { slide as Menu } from 'react-burger-menu';

import './Navbar.css';
import Radium from 'radium';
import BackgroungUrl from '../images/Header_admin.jpg';
import MobileBackgroungUrl from '../images/Header_admin_Mobile.jpg';

class Navbar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			ManuIsOpen: false,
		}
	}


	handleLogout = () => {
		localStorage.clear();
		window.location.href = '/';
	};

	render() {
		const userName = (getUserName()) ? getUserName() : '';

		const BackgroundStyle = {
			backgroundImage: 'url(' + BackgroungUrl + ')',

			//On moble screen
			'@media (max-width: 801px)': {
				backgroundImage: 'url(' + MobileBackgroungUrl + ')',
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
			}

		};

		return (
			<>
				<Menu right>
					<NavLink to={`/admin/Franchise_Owners_List`}>Franchise Owners</NavLink>
					<NavLink to={`/admin/sign_up_new_franchise/${userName}`}>Sign Up New Franchise</NavLink>
					<NavLink to={`/building_instructions/${userName}`}>Building Instructions</NavLink>
					{/* <NavLink to={`/lesson_plans/${userName}`}>Lesson Plans</NavLink> */}
					<div className='LogOutMobile NavLinkMobile' onClick={this.handleLogout} >Log out</div>
				</Menu>

				<div className={styles.NavigationContainer}>



					<div style={BackgroundStyle} className={styles.NavBar + ' NavBarAdmin'} >
						<div className={styles.LeftDiv}>
							<div className={styles.userName}>
								{userName}
								{/* {userName}'s dashboard */}
							</div>
						</div>
						<div className={styles.CenterDivAdmin}>

							<NavLink to={`/admin/Franchise_Owners_List`}>Franchise Owners</NavLink>
							<NavLink to={`/admin/all_students_list`}>All Students</NavLink>
							<NavLink to={`/admin/sign_up_new_franchise`}>Sign Up New Franchise</NavLink>
							<NavLink to={`/building_instructions/${userName}`}>Building Instructions</NavLink>
							{/* <NavLink to={`/lesson_plans/${userName}`}>Lesson Plans</NavLink> */}

						</div>
						<div className={styles.RightDiv}>

							<div>
								<button onClick={this.handleLogout} className={styles.LogOutBt + ' logoutBtn'}>Log Out</button>
								<NavLink className="TermsNavLink" to={`/terms_of_use`}>Terms Of Use</NavLink>
							</div>

						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Radium(Navbar);


