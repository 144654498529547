import React, { Component } from 'react';

import styles from '../../screens/BuildingInstructions.module.css';

import { isLoggedIn, isFranchiseOwner, isAdministrator } from "../../components/functions";

import NavbarAdmin from "../../components/NavbarAdmin";
import Navbar from "../../components/Navbar";
import NavbarStudents from "../../components/NavbarStudents";

import { useMediaQuery } from 'react-responsive';

import Radium from 'radium';
import { withRouter } from 'react-router-dom'

import PlayMachines1 from '../SingleProgram/PLAY_MACHINES_1/PlayMachines1LessonsPlans';
import PlayMachines2 from '../SingleProgram/PLAY_MACHINES_2/PlayMachines2LessonsPlans';

import BackgroungUrl from '../../images/PLAY_MACHINES_1.jpg';
import MobileBackgroungUrl from '../../images/PLAY_MACHINES_1_Mobile.jpg';

import LogoPlayMachines1 from '../../images/LogoPlayMachines1.png';
import LogoPlayMachines2 from '../../images/LogoPlayMachines2.png';


const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 767 })
    return isDesktop ? children : null
}


class ModelsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Program: '',
        }
    }

    componentDidMount() {
        this.setState({ Program: this.props.programName });
    }


    renderSwitchForLogo(param) {
        switch (param) {
            case 'PlayMachines1':
                return <img src={LogoPlayMachines1} alt="Play Machines 1"></img>;
            case 'PlayMachines2':
                return <img src={LogoPlayMachines2} alt="Play Machines 2"></img>;
            default:
                return null;
        }
    };



    renderSwitch(param) {
        switch (param) {
            case 'PlayMachines1':
                return <PlayMachines1 />;
            case 'PlayMachines2':
                return <PlayMachines2 />;
            default:
                return null;
        }
    };


    render() {
        const { match } = this.props;
        const ProgramName = match.params.programName;
        const ProgramNameNoSpaces = ProgramName.replace(/\s+/g, '');
        // console.log(this.props)

        let Header = <Navbar />;
        isFranchiseOwner() ?
            (
                isAdministrator() ? Header = <NavbarAdmin /> : Header = <Navbar />
            )
            : (
                Header = <NavbarStudents Title="Open Your Model" GoBack="<< Go Back" />
            )


        let title = null
        isFranchiseOwner() ?
            (
                title = <div className={styles.Title}>{match.params.programName}</div>
            )
            : (
                title = <div className={styles.Title}></div>
            )


        let GoBack
        isFranchiseOwner() ?
            (

                GoBack = <div onClick={() => this.props.history.goBack()} className={styles.GoBack}>{"<< "}Go Back</div>
            )
            : (
                GoBack = null
            )


        return (


            !isLoggedIn() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>
                    {Header}
                 
                    <div className='PageConatiner'>
                        {/* {title} */}
                        <div className={styles.ProgramLogo} >
                            {this.renderSwitchForLogo(ProgramNameNoSpaces)}
                           
                        </div>

                        <div onClick={() => this.props.history.goBack()} className={styles.GoBackLessonPlan}>{"<< "}Go Back</div>
                        
                        <Desktop> <div className={styles.BackgroungImg}><img src={BackgroungUrl} alt="young engineers logo"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={MobileBackgroungUrl} alt="young engineers logo"></img></div> </Mobile>

                        {this.renderSwitch(ProgramNameNoSpaces)}

                    </div>
                </React.Fragment>
        );


    }
}

export default withRouter(ModelsPage);
