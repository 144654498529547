import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../client-config';
import ThSort from './ThSort';
import Loader from "../loader.gif";
import Moment from 'react-moment';
import styles from '../screens/StudentsList.module.css';
import { Link, withRouter } from "react-router-dom";

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import userIcon from '../images/user_icon.png';

import { Table, Tbody, Thead, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { getUserName } from "../components/functions";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { RiFileExcel2Line } from 'react-icons/ri';

@inject('usersList')
@observer // REMOVE BRACKETS => @observer
class TableAllStudents extends Component {


    constructor(props) {
        super(props);

        this.state = {
            USERS: [],
            teacher_id: localStorage.getItem('userId'),
            loading: false,
            HaveSudents: true,
            th1_Active: false,

            SortBy: '',

            th1_Direction: false,
            th2_Direction: false,
            th3_Direction: false,
            th4_Direction: false,
            th5_Direction: false,
            th6_Direction: false,
            th7_Direction: false,
            th8_Direction: false,
            th9_Direction: false,

            th1_Active: false,
            th2_Active: false,
            th3_Active: false,
            th4_Active: false,
            th5_Active: false,
            th6_Active: false,
            th7_Active: false,
            th8_Active: false,
            th9_Active: false,
        }
    }

    componentDidMount() {
        //   console.log(this.props.usersList)
    };




    ToggleIcon = (th) => {
        const direction = th + '_Direction';
        const active = th + '_Active';

        // MAKE ACTIVE SELECTED
        if (!this.state[active]) {

            this.setState({ th1_Active: false });
            this.setState({ th2_Active: false });
            this.setState({ th3_Active: false });
            this.setState({ th4_Active: false });
            this.setState({ th5_Active: false });
            this.setState({ th6_Active: false });
            this.setState({ th7_Active: false });
            this.setState({ th8_Active: false });
            this.setState({ th9_Active: false });

            this.setState({ [active]: true });

        }

        const thisth = this.state[direction]
        this.setState({ [direction]: !thisth });

        this.SwitchSort(th)
    }


    SwitchSort = (th) => {
        const direction = th + '_Direction';
        const active = th + '_Active';
        const thisth = this.state[direction]

        const Isactive = this.state[active]
        const users = this.props.usersList.AllStudents

        if (th == 'th1') {
            if (thisth) {
                // SORT A TO Z
                this.setState({ SortBy: 'student_first_nameA' })
            }

            if (!thisth) {
                // SORT Z TO A
                this.setState({ SortBy: 'student_first_nameZ' })
            }
        }


        if (th == 'th2') {
            if (thisth) {
                // SORT A TO Z
                this.setState({ SortBy: 'student_last_nameA' })
            }

            if (!thisth) {
                // SORT Z TO A
                this.setState({ SortBy: 'student_last_nameZ' })
            }
        }



        if (th == 'th3') {
            if (thisth) {
                // SORT A TO Z
                this.setState({ SortBy: 'parent_nameA' })
            }

            if (!thisth) {
                // SORT Z TO A
                this.setState({ SortBy: 'parent_nameZ' })
            }
        }




        if (th == 'th6') {
            if (thisth) {
                // SORT A TO Z
                this.setState({ SortBy: 'programA' })
            }

            if (!thisth) {
                // SORT Z TO A
                this.setState({ SortBy: 'programZ' })
            }
        }

        if (th == 'th7') {
            if (thisth) {
                // SORT A TO Z
                this.setState({ SortBy: 'lessons_start_dateA' })
            }

            if (!thisth) {
                // SORT Z TO A
                this.setState({ SortBy: 'lessons_start_dateZ' })
            }
        }


        if (th == 'th8') {
            if (thisth) {
                // SORT A TO Z
                this.setState({ SortBy: 'lessons_end_dateA' })
            }

            if (!thisth) {
                // SORT Z TO A
                this.setState({ SortBy: 'lessons_end_dateZ' })
            }
        }


        if (th == 'th9') {
            if (thisth) {
                // SORT A TO Z
                this.setState({ SortBy: 'number_of_lessonsUp' })
            }

            if (!thisth) {
                // SORT Z TO A
                this.setState({ SortBy: 'number_of_lessonsDown' })
            }
        }



    }


    getUserById(Id) {
        const Filterd = this.props.usersList.FilterdUserName.filter(user => user.user_id.toString() === Id.toString())
        const USERNAME = Filterd.map((user) =>
            <>
                {user.FirstName}<br></br>
                {user.Country}<br></br>
                {user.State}

            </>
        )
        return USERNAME
    }



    render() {



        const { loading,

            th1_Active,
            th2_Active,
            th3_Active,
            th4_Active,
            th5_Active,
            th6_Active,
            th7_Active,
            th8_Active,
            th9_Active,

            th1_Direction,
            th2_Direction,
            th3_Direction,
            th4_Direction,
            th5_Direction,
            th6_Direction,
            th7_Direction,
            th8_Direction,
            th9_Direction,



        } = this.state;




        // Email: "Aff@aas.crr"
        // FirstName: ""
        // LastName: ""
        // RegisteredDate: "2020-04-26 17:14:01"
        // lessons_end_date: "2020-04-21T21:00:00.000Z"
        // lessons_start_date: "2020-04-20T21:00:00.000Z"
        // number_of_lessons: "22"
        // parent_email: "Aff@aas.crr"
        // parent_name: "Ff"
        // phone: "+972525521108"
        // program: "Play Machines 1"
        // role: "student"
        // student_first_name: "גיא"
        // student_last_name: "סלח"
        // teacher_id: "65"
        // user_id: 77


        // ThisTeacherStudents.sort((a, b) => a.user_id - b.user_id)

        // ThisTeacherStudents.sort((a, b) => a.lessons_start_date - b.lessons_start_date)


        const userName = (getUserName()) ? getUserName() : '';
        // console.log(this.props.usersList.AllStudents)
        const tablelist = this.props.usersList.getAllStudents(this.state.SortBy).map((student) =>
            <Tr key={student.user_id}>
                <Td>{this.getUserById(student.teacher_id)}</Td>
                <Td>{student.student_first_name}</Td>
                <Td>{student.student_last_name}</Td>
                <Td>{student.parent_name}</Td>
                <Td>{student.parent_email}</Td>
                <Td>{student.phone}</Td>
                <Td>{student.program}</Td>
                <Td >{<Moment format="MMMM DD, YYYY">{student.lessons_start_date}</Moment>}</Td>
                <Td >{<Moment format="MMMM DD, YYYY">{student.lessons_end_date}</Moment>}</Td>
                <Td>{student.number_of_lessons}</Td>
            </Tr>
        );

        const FullTable = <>
            <div className={styles.Title}>All Students List</div>

            <Table>

                <Thead>
                    <Tr>
                        <Th>Franchise Owners</Th>
                        <Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th1')} Direction={th1_Direction} Active={th1_Active} Title="Student first name" /></Th>
                        <Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th2')} Direction={th2_Direction} Active={th2_Active} Title="Student last name" /></Th>
                        <Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th3')} Direction={th3_Direction} Active={th3_Active} Title="Parent name" /></Th>
                        <Th>Parent email</Th>
                        <Th>Phone</Th>
                        <Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th6')} Direction={th6_Direction} Active={th6_Active} Title="Program" /></Th>
                        <Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th7')} Direction={th7_Direction} Active={th7_Active} Title="Start Date" /></Th>
                        <Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th8')} Direction={th8_Direction} Active={th8_Active} Title="End Date" /></Th>
                        <Th><ThSort OnClick={this.ToggleIcon.bind(this, 'th9')} Direction={th9_Direction} Active={th9_Active} Title="Lessons" /></Th>

                    </Tr>
                </Thead>

                <Tbody>
                    {tablelist}
                </Tbody>
            </Table>
            {/* <div className={styles.TableComment}>To change user info plaese contact our team at support@youngengineers.org</div> */}
            <div className="download-table-xls-container">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="TeachersStudents"
                    filename={userName + ' Youngengineer Online'}
                    sheet={userName + ' Students'}
                    buttonText={<RiFileExcel2Line />} />
            </div>
        </>

        // THIS IS DUCPLICATE as above table for download with out multiheaders  (<Thead> was removed)
        const FullTableToDownload = <>

            <Table id="TeachersStudents">
                <Tr>
                    <Th>Franchise Owners</Th>
                    <Th>Student First Name </Th>
                    <Th>Student Last Name</Th>
                    <Th>Parent Name</Th>
                    <Th>Parent Email</Th>
                    <Th>Phone</Th>
                    <Th>Program</Th>
                    <Th >Start Data</Th>
                    <Th>End Data</Th>
                    <Th>Lessons</Th>

                </Tr>

                <Tbody>
                    {tablelist}
                </Tbody>
            </Table>
        </>
        // THIS IS DUCPLICAT as above table for download with out multiheaders 

        // console.log(this.props.usersList)

        return (

            <>
                <div className="hidden">{FullTableToDownload}</div>


                {this.state.HaveSudents ?
                    FullTable
                    :
                    null
                }
                <div className={styles.FlexContainer}>
                    <div className={styles.SingUpNewLink}>
                        <Link to={`/sign_up_new_student/${userName}`}>
                            <div className={styles.StudentListAddUser}>
                                <img src={userIcon} alt="userIcon"></img>
                                <div className={styles.AddStudentText}>Sign Up New Student</div>
                            </div></Link>

                        {this.props.usersList.loading && <img className="loader" src={Loader} alt="Loader" />}
                    </div>
                </div>
            </>

        );
    }

}



export default withRouter(TableAllStudents);

