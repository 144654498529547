import React from 'react';
import styles from './SelectProgram.module.css';

import TopTitleBackground from '../images/top_GreenDuble.png'
import BottomShadow from '../images/BottomShadow.png'

class SelectProgramItem extends React.Component {




    render() {

        const style = {
            top: this.props.TopPosition,
            position: 'relative',
        };

    
        return (

            <>
                    <div className={styles.TitleContainer}>
                        <div className={styles.Title}>{this.props.Title}<span className={styles.RomanNumber}>{this.props.RomanNumber}</span></div>
                        <img className={styles.TopTitleBackground} src={this.props.TopLegoImage} alt="TopTitleBackground"></img>
                    </div>

                    <div className={styles.ProgramLogo}>
                        <img className={styles.TopTitleBackground} style={style} src={this.props.ProgramLogo} alt="ProgramLogo"></img>
                    </div>

                    <div className={styles.BottomShadow}>
                        <img className={styles.TopTitleBackground} src={BottomShadow} alt="BottomShadow"></img>
                    </div>
             
         </>

        );
    }
}

export default SelectProgramItem;