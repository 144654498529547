import React, { Component } from 'react';

import styles from './StudentsList.module.css';

import { useMediaQuery } from 'react-responsive';

import { isLoggedIn, isFranchiseOwner, isAdministrator } from "../components/functions";

import NavbarAdmin from "../components/NavbarAdmin";
import Navbar from "../components/Navbar";

import TableTeacherStudents from '../components/TableTeacherStudents'
import TableTeacherStudentsAdmin from '../components/TableTeacherStudentsAdmin'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'


import BackgroungUrl from '../images/WhiteBackground.jpg';
import MobileBackgroungUrl from '../images/WhiteBackgroundMobile.jpg';


const Desktop = ({ children }) => {
	const isDesktop = useMediaQuery({ minWidth: 768 })
	return isDesktop ? children : null
}

const Mobile = ({ children }) => {
	const isDesktop = useMediaQuery({ maxWidth: 767 })
	return isDesktop ? children : null
}


class StudentsList extends Component {

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.location.pathname !== this.props.location.pathname) {
			window.location.reload();
		}
	};


	render() {


		const BackgroundStyle = {
			backgroundImage: 'url(' + BackgroungUrl + ')',

			//On moble screen
			'@media (max-width: 801px)': {
				backgroundImage: 'url(' + MobileBackgroungUrl + ')',
			}

		};

		return (

			!isLoggedIn() || !isFranchiseOwner() ? (

				window.location.href = '/'

			) :


				<React.Fragment>
					<Desktop> <div className={styles.BackgroungImg}><img src={BackgroungUrl} alt="dvb"></img></div> </Desktop>
					<Mobile> <div className={styles.MobileBackgroungImg}><img src={MobileBackgroungUrl} alt="dvb"></img></div> </Mobile>

					{isAdministrator() ? <NavbarAdmin /> : <Navbar />}

					<div  className={styles.PageConatiner}>


						<div className={styles.Container}>

							{isAdministrator() ? <TableTeacherStudentsAdmin /> : <TableTeacherStudents />
							}



						</div>

					</div>

				</React.Fragment>
		);
	}

}

export default StudentsList;



