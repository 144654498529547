import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../client-config';
import styles from './BuildingInstructions.module.css';

import { isLoggedIn, isFranchiseOwner, isAdministrator } from "../components/functions";

import Navbar from "../components/Navbar";
import NavbarAdmin from "../components/NavbarAdmin";

import { useMediaQuery } from 'react-responsive';

import SelectLessonPlansGrid from '../components/SelectLessonPlansGrid'


import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import BackgroungUrl from '../images/WhiteBackground.jpg';
import MobileBackgroungUrl from '../images/WhiteBackground.jpg';



const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 767 })
    return isDesktop ? children : null
}


class LessonPlans extends Component {

    handleFormSubmit = (event) => {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        const UserId = localStorage.getItem('userId')

        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.filter = UserId

        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/user_roles`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                // console.log(res.data)
            })
            .catch(err => {
            })


    };

    render() {

        const BackgroundStyle = {
            backgroundImage: 'url(' + BackgroungUrl + ')',

            //On moble screen
            '@media (max-width: 801px)': {
                backgroundImage: 'url(' + MobileBackgroungUrl + ')',
            }

        };


        return (

            !isLoggedIn() || !isFranchiseOwner() ? (

                window.location.href = '/'

            ) :


                <React.Fragment>
                   { isAdministrator() ? <NavbarAdmin /> :<Navbar />}


                    <div className='PageConatiner'>
                        <div className={styles.Title}>Lesson plans</div>

                        <Desktop> <div className={styles.BackgroungImg}><img src={BackgroungUrl} alt="young engineers logo"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={MobileBackgroungUrl} alt="young engineers logo"></img></div> </Mobile>
                        <SelectLessonPlansGrid />

                    </div>
                </React.Fragment>
        );


    }
}

export default LessonPlans;
