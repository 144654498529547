import React from 'react';
import { Link } from 'react-router-dom'
import styles from '../../screens/SingleProgram/Programs.module.css';

import { isLoggedIn, isFranchiseOwner } from "../../components/functions";
import TopTitleBackground from '../../images/top_green.png'
import TopTitleBackgroundGray from '../../images/top_dark_gray.png'
import BottomShadow from '../../images/BottomShadow.png'


class SelectProgramItem extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordValu: this.props.Password,
            EnterPassword: true,
            triggerShake: '',
            TitleBackColor: this.props.TitleBackColor,

        }
    }

    handleOnChange = (event) => {
        event.preventDefault()

        if (event.target.value.length <= 4) {
            this.setState({ [event.target.name]: event.target.value });
        }

        if (event.target.value.length == 4 && event.target.value != this.state.passwordValu) {
            this.setState({ triggerShake: 'shake' });

            setTimeout(function () {
                this.setState({ triggerShake: '' });
                // this.setState({  password: ''});
            }.bind(this), 1000);  // wait 1 seconds, then reset 

        }

        if (event.target.value == this.state.passwordValu) {
            localStorage.setItem(this.props.localStorageItemName, true);
            this.setState({ EnterPassword: false });

        }
    };




    render() {





        const style = {
            top: this.props.TopPosition,
            position: 'relative',
        };


        const ShowPassword = () => (
            <Link to={`/model_building_instructions/${this.props.userName}/${this.props.programName}/${this.props.Title}`}>
                <div className={styles.OpenCodeArea}>
                    <div className={styles.code}>Code: {this.state.passwordValu}</div>
                    <div className={styles.Arrow}>>></div>
                </div>
            </Link>)


        const { password } = this.state;
        const triggerShake = this.state.triggerShake;

        let PasswordField = () => (null)


        if (this.state.EnterPassword) {
            PasswordField = () => (
                <>
                    <div className={styles.CodeArea}>
                        <div>Password</div>
                        <input
                            placeholder=""
                            type="number"
                            className="form-input"
                            name="password"
                            value={password}
                            onChange={this.handleOnChange}
                        // ref={function (input) {
                        //     if (input) {
                        //         input.focus();
                        //     }
                        // }}
                        />
                    </div>
                </>
            )
        }
        else {

            PasswordField = () => (
                <>

                    <Link to={`/model_building_instructions/${this.props.userName}/${this.props.programName}/${this.props.Title}`}>
                        <div className={styles.OpenCodeArea}>
                            <div>Building instructions</div>
                            <div className={styles.Arrow}>>></div>
                        </div>
                    </Link>
                </>
            )
        }

        let TopTitleBackgroundColor = ''

        if (this.state.TitleBackColor == 'green') {
            TopTitleBackgroundColor = TopTitleBackground
        }

        if (this.state.TitleBackColor == 'gray') {
            TopTitleBackgroundColor = TopTitleBackgroundGray
        }


        return (

            <>

                <div className={styles.Item + ' ' + triggerShake}>
                    <div className={styles.TitleContainer}>
                    <div className={styles.Title + ' ' + this.props.TitleclassName}>{this.props.Title}</div>
                        <img className={styles.TopTitleBackground} src={TopTitleBackgroundColor} alt="TopTitleBackground"></img>
                    </div>

                    <div className={styles.ModelPic}>
                        <img className={styles.TopTitleBackground} style={style} src={this.props.ModelPic} alt="ModelPic"></img>
                    </div>

                    {isFranchiseOwner() ?
                        (
                            ShowPassword()
                        )
                        : (
                            PasswordField()
                        )}


                    <div className={styles.BottomShadow}>
                        <img className={styles.TopTitleBackground} src={BottomShadow} alt="BottomShadow"></img>
                    </div>
                </div>


            </>

        );
    }
}

export default SelectProgramItem;